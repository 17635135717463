import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { motion } from 'framer-motion';

const CookieBanner = () => {
  const [cookieBarVisible, setCookieBarVisible] = useState(false);
  useEffect(() => {
    let pop_status = localStorage.getItem('pop_status');
    if (!pop_status) {
      setCookieBarVisible(true);
    }
  }, []);

  const acceptCookies = () => {
    localStorage.setItem('pop_status', 1);
    setCookieBarVisible(false);
  };

  return (
    <>
      {cookieBarVisible && (
        <CookiePopup>
          <Content>
            <p>
              We use anonymous Google Analytics cookies to help improve our service. To learn more{' '}
              <AnchorLink to="/privacy#cookies">click here</AnchorLink>.
            </p>
            <button onClick={() => acceptCookies()}>Accept</button>
          </Content>
        </CookiePopup>
      )}
    </>
  );
};
const Content = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const CookiePopup = styled(motion.div)`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  background: #252525;
  color: white;
  font-size: 0.875rem;
  display: flex;
  p {
    font-family: 'Poppins', sans-serif;
    a {
      color: rgb(50, 175, 255);
      text-decoration: underline;
      font-family: 'Poppins', sans-serif;
    }
  }
  z-index: 5;
  button {
    cursor: pointer;
    padding: 0.5rem 1.5rem;
    border-radius: 0.25em;
    font-size: 0.75em;
    background: rgb(30, 144, 255);
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    letter-spacing: 1pt;
    transition: 0.375s;
    &:hover {
      background: rgb(50, 175, 255);
    }
  }
`;

export default CookieBanner;
