import * as React from 'react';

function SvgInstaIcon(props) {
  return (
    <svg viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M15.263 5.913a1.167 1.167 0 10-.002-2.333 1.167 1.167 0 00.002 2.333zM10.075 5.084c-2.707 0-4.925 2.223-4.925 4.935 0 2.711 2.218 4.934 4.925 4.934S15 12.73 15 10.019c0-2.712-2.218-4.935-4.925-4.935zm0 8.098c-1.73 0-3.158-1.43-3.158-3.163 0-1.733 1.429-3.164 3.158-3.164 1.73 0 3.158 1.431 3.158 3.164 0 1.732-1.428 3.163-3.158 3.163z"
        fill="#fff"
      />
      <path
        d="M13.985 20h-7.97C2.707 20 0 17.288 0 13.974V6.026C0 2.712 2.707 0 6.015 0h7.97C17.293 0 20 2.712 20 6.026v7.985C19.962 17.326 17.293 20 13.985 20zM6.015 1.883A4.127 4.127 0 001.88 6.026v7.985c0 2.26 1.842 4.143 4.135 4.143h7.97a4.151 4.151 0 004.135-4.143V6.026c0-2.26-1.842-4.143-4.135-4.143h-7.97z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgInstaIcon;
