import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FacebookIcon, TwitterIcon, LinkedinIcon, InstaIcon } from 'assets/svgs';
import { motion, AnimatePresence } from 'framer-motion';
import { wrap } from '@popmotion/popcorn';
import { Container, FadeIn } from 'components';
import { useWindowSize } from 'hooks';

export default ({ slides }) => {
  const { windowHeight, windowWidth } = useWindowSize();
  const swipeConfidenceThreshold = 10000;
  const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
  };
  const [[page, direction], setPage] = useState([0, 0]);
  const paginate = (dir) => {
    setPage([page + dir, dir]);
  };

  useEffect(() => {
    const timer = setTimeout(() => paginate(1), 10000);
    return () => clearTimeout(timer);
  }, [page]);

  const index = wrap(0, slides.length, page);

  const variants = {
    enter: (direction) => {
      return {
        x: direction > 0 ? 300 : -300,
        opacity: 0
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1
    },
    exit: (direction) => {
      return {
        zIndex: 0,
        x: direction < 0 ? 300 : -300,
        opacity: 0
      };
    }
  };

  return (
    <Container>
      <FadeIn>
        <AnimatePresence initial={false} custom={direction}>
          <Carousel windowHeight={windowHeight}>
            <Est>Est. 2005</Est>
            <Slide
              key={page}
              src={slides[index]}
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{ duration: 0.5 }}
              onPan={(e, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x);

                if (swipe < -swipeConfidenceThreshold) {
                  paginate(1);
                } else if (swipe > swipeConfidenceThreshold) {
                  paginate(-1);
                }
              }}>
              <Banner
                image={slides[index].node.slideImage.asset.gatsbyImageData}
                alt={slides[index].node.caption}
              />
              <Content>
                <Caption>{slides[index].node.caption}</Caption>
              </Content>
            </Slide>
            {windowWidth >= 768 && (
              <Socials>
                <Social
                  aria-label="Facebook"
                  href="https://business.facebook.com/colinmaherdesign/"
                  target="blank">
                  <FacebookIcon />
                </Social>
                <Social aria-label="Twitter" href="https://twitter.com/" target="blank">
                  <TwitterIcon />
                </Social>
                <Social
                  aria-label="Linked In"
                  href="https://www.linkedin.com/company/18601203/"
                  target="blank">
                  <LinkedinIcon />
                </Social>
                <Social
                  aria-label="Instagram"
                  href="https://www.instagram.com/colinmaherdesign/"
                  target="blank">
                  <InstaIcon />
                </Social>
              </Socials>
            )}
          </Carousel>
        </AnimatePresence>
      </FadeIn>

      <Dots style={{ paddingLeft: 0 }}>
        <Paginate
          aria-label="Slide 1"
          active={slides[index].node.orderNumber === 1}
          onClick={() => setPage([0, 1])}
        />
        <Paginate
          aria-label="Slide 2"
          active={slides[index].node.orderNumber === 2}
          onClick={() => setPage([1, 1])}
        />
        <Paginate
          aria-label="Slide 3"
          active={slides[index].node.orderNumber === 3}
          onClick={() => setPage([2, 1])}
        />
        <Paginate
          aria-label="Slide 4"
          active={slides[index].node.orderNumber === 4}
          onClick={() => setPage([3, 1])}
        />
        <Paginate
          aria-label="Slide 5"
          active={slides[index].node.orderNumber === 5}
          onClick={() => setPage([4, 1])}
        />
      </Dots>
    </Container>
  );
};

const Est = styled.span`
  position: absolute;
  font-size: 1.25rem;
  color: var(--muted);
  font-family: 'Poppins', serif;
  font-weight: 400;
  font-style: normal;
  top: 1rem;
  left: -4.5rem;
  transform: translateY(50%) rotate(-90deg);
  letter-spacing: 1pt;
  @media (min-width: 768px) {
    left: -4rem;
  }
`;

const Social = styled.a`
  svg {
    height: 1rem;
    width: 1rem;
    transition: 0.5s;

    path {
      transition: 0.5s;
      fill: var(--muted);
    }
  }
  &:hover {
    svg {
      transform: scale(1.1);
      path {
        fill: var(--main);
      }
    }
  }
`;

const Socials = styled.div`
  position: absolute;
  z-index: 2;
  top: 50%;
  right: -8.75rem;
  transform: translateY(-50%) rotate(90deg);
  width: 12.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (min-width: 768px) {
    right: -8rem;
  }
`;

const Paginate = styled.button`
  cursor: pointer;
  width: 0.75rem;
  height: 0.75rem;
  outline: none;
  border: none;
  transition: 0.5s;
  background: ${({ active }) => (active ? 'rgba(0,0,0,.25)' : 'transparent')};
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  margin: 0 0.75rem;
  &:hover {
    background: rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(0, 0, 0, 0.25);
  }
  &:focus {
    background: rgba(0, 0, 0, 0.15);
    border: 1px solid rgba(0, 0, 0, 0.25);
  }
`;

const Dots = styled.div`
  width: auto;
  height: 1rem;
  display: flex;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0rem;
`;

const Caption = styled.h1`
  font-family: 'Poppins', serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 2px;
  font-size: 1.25rem;
  color: white;
  margin: 0;
  margin-bottom: 0;
  line-height: 1.5em;
  color: var(--muted);
  text-align: right;
`;

const Content = styled.div`
  position: absolute;
  z-index: 2;
  bottom: -4rem;
  right: 0;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  @media (min-width: 768px) {
    justify-content: flex-end;

    bottom: -4rem;
    right: -1rem;
  }
  @media (min-width: 1200px) {
    bottom: -4rem;
  }
`;

const Slide = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: relative;
  cursor: grab;
  touch-action: pan-y;
`;

const Banner = styled(GatsbyImage)`
  width: 100%;
  height: 75vh;
  z-index: 1;
  img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    pointer-events: none;
  }
  @media (min-width: 768px) {
    height: 100%;
  }
`;

const Carousel = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
  height: auto;
  overflow: visible;
  margin-bottom: 6rem;
  @media (min-width: 768px) {
    max-width: 100%;
    height: 27.5rem;
    margin-top: 13.5rem;
    margin-bottom: 4rem;
  }
  @media (min-width: 1200px) {
    width: 100%;
    margin-top: ${({ windowHeight }) => (windowHeight > 1000 ? '12rem' : '10.75rem')};
    margin-bottom: 4rem;
    max-height: ${({ windowHeight }) => `calc(${windowHeight}px - 16rem)`};
    height: 42.5rem;
  }
`;
