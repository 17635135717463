/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Button } from '../../../styles/mixins';

function encode(data) {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

export default () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formValues, setFormValues] = useState([]);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const handleChange = (e) => {
    const changedElement = e.target;
    const { name, value } = changedElement;
    setFormValues({
      ...formValues,
      [name]: value
    });
    console.log(formValues);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...formValues
      })
    })
      .then(() => setFormSubmitted(true))
      .catch((error) => alert(error));
  };

  return (
    <>
      {!formSubmitted && (
        <ContactForm
          id="contactform"
          name="contact"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}>
          <input type="hidden" name="contact-form" value="contact-form" />
          <label htmlFor="subject" required>
            Quote Type: *
          </label>
          <select name="subject" onChange={handleChange}>
            <option value="" disabled selected>
              Choose Quote Type
            </option>
            <option value="Quote - New Build / Extension">New Build/Extension</option>
            <option value="[Quote] Existing Space">Existing Space</option>
          </select>

          <label htmlFor="name">Client Name: *</label>
          <input
            type="text"
            name="name"
            placeholder="Your name here..."
            onChange={handleChange}
            autoComplete="on"
            required
          />
          <label htmlFor="phone">Contact Number: *</label>
          <input
            type="tel"
            name="phone"
            placeholder="Your contact number..."
            autoComplete="on"
            onChange={handleChange}
            required
          />
          <label htmlFor="email">E-mail Address: *</label>
          <input
            type="email"
            name="email"
            placeholder="Your email address here..."
            onChange={handleChange}
            autoComplete="on"
            required
          />

          <label htmlFor="site-address">Site Address: *</label>
          <textarea
            name="site-address"
            placeholder="Site address here..."
            onChange={handleChange}
            required
          />
          <label htmlFor="reference">Where did you hear about us?:</label>
          <textarea
            name="reference"
            placeholder="..."
            onChange={handleChange}
            required
          />

          <label htmlFor="timline">Estimated Timline: *</label>
          <textarea
            name="timline"
            placeholder="Your estimated timline for when you will need your project..."
            onChange={handleChange}
            required
          />
          <label htmlFor="budget" required defaultValue="€14,000 - €20,000">
            To help us establish a budget for your project, please indicate which of these options
            would be most accurate.
            <br />
            <small>
              (Budget includes kitchen cabinetry and worktops, but won’t include kitchen appliances)
            </small>
            </label>
            <br/>
            <p style={{marginBottom: '0.5em'}}>
              €18,000 - €25,000 :{' '}
              <span style={{ opacity: 0.75 }}>
              Typically this section would cater for our modern and Springhill range.
              </span>
            </p>
            <p style={{marginBottom: '0.5em'}}>
              €25,000 - €35,000 :{' '}
              <span style={{ opacity: 0.75 }}>
              This section would represent our Truly bespoke classic range with no restrictions in doorstyle, colour and design.
              </span>
            </p>
            <p style={{marginBottom: '0.5em'}}>
              €35,000+ :{' '}
              <span style={{ opacity: 0.75 }}>
              Typically large kitchens form our heritage and in-frame ranges, hand painted colour of choice.
              </span>
            </p>
            <br/>
          <select name="budget" onChange={handleChange} required>
            <option value="" disabled selected>
              Select Your Budget
            </option>
            <option value="€18,000 - €25,000">€18,000 - €25,000</option>
            <option value="€25,000 - €35,000">€25,000 - €35,000</option>
            <option value="€35,000+">€35,000+</option>
          </select>

          <label htmlFor="additional-info">Any additional info:</label>
          <textarea
            name="additional-info"
            placeholder="Type your message here..."
            onChange={handleChange}
          />
          <Terms>
            <input
              type="checkbox"
              id="terms_and_conditions"
              checked={termsAccepted}
              value="1"
              onChange={() => {
                setTermsAccepted(!termsAccepted);
              }}
            />
            <p>I agree to having the above details stored for future communications.</p>
          </Terms>
          <StyledButton contrast type="submit" disabled={!termsAccepted}>
            Submit
          </StyledButton>
        </ContactForm>
      )}
      {formSubmitted && (
        <ThankYou
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}>
          <p>Thank you! Your form has been successfully submitted.</p>
        </ThankYou>
      )}
    </>
  );
};

const Terms = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  input[type='checkbox'] {
    width: 1rem;
    height: 1rem;
    margin-top: 2px;
    margin-right: 1rem;
    border: 1px solid white;
  }
  p {
    color: var(--muted);
    font-size: 0.75rem !important;
    font-family: 'Poppins Light' !important;
  }
`;

const StyledButton = styled(Button)`
  width: 8.75rem;
  height: 3rem;
  align-self: flex-end;
  transition: 0.5s;
  background: var(--muted);
  margin: 0;
  margin-bottom: 2rem;
  cursor: pointer;
  box-shadow: 4px 4px 10px rgba(3, 20, 103, 0.3);
  &:hover {
    background: var(--main);
    color: white;
  }
  &:disabled {
    background: var(--muted);
    cursor: not-allowed;
    color: white;

    opacity: 0.75;
  }
`;

const ThankYou = styled(motion.div)`
  grid-area: form;
  background: #eaf6ff;
  max-height: 150px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.425rem;
  margin-top: 1.5rem;
  p {
    margin: 2rem 0;
    text-align: center;
  }

  @media (min-width: 1150px) {
    grid-column: 2/6;
    padding: 2rem;
  }
`;

const ContactForm = styled.form`
  width: 100%;
  grid-area: form;
  display: flex;
  flex-direction: column;
  height: auto;
  small {
    font-family: 'Poppins Light';
    font-weight: bold;
  }

  input,
  select {
    height: 2.5rem;
    width: 100%;
    background: white;
    border: 1px solid var(--muted);
    color: var(--main);
    outline: none;
    font-family: 'Poppins Light';
    letter-spacing: 1px;
    font-size: 0.9rem;
    padding: 0.5rem 0.75rem;
    margin-bottom: 2rem;
    border-radius: 0.2rem;
    max-width: 550px;

    &:hover {
      border: 1px solid var(--muted);
    }
    &:focus {
      border: 1px solid var(--main);
    }
  }
  label {
    font-size: 0.875rem;
    color: var(--main);
    font-family: 'Poppins Light';
    margin-bottom: 0.5rem;
    letter-spacing: 1px;
  }
  textarea {
    font-family: 'Poppins Light';
    height: 100px;
    width: 100%;
    max-width: 550px;
    border: 1px solid var(--muted);
    outline: none;
    background: white;
    color: var(--main);
    font-size: 0.9rem;
    padding: 0.5rem 0.75rem;
    margin-bottom: 2rem;
    border-radius: 0.2rem;
    letter-spacing: 1px;
    &::placeholder {
      color: var(--muted);
      font-size: 0.875rem;
      font-family: 'Poppins Light';
    }
    &:hover {
      border: 1px solid var(--muted);

      outline: none;
    }
    &:focus {
      border: 1px solid var(--main);
    }
  }
  @media (min-width: 1150px) {
    textarea {
      height: 120px;
    }
  }
`;
