import * as React from 'react';

function SvgFacebookIcon(props) {
  return (
    <svg viewBox="0 0 10 22" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.203 11.087V21.74c0 .173.128.26.255.26h3.898c.17 0 .254-.13.254-.26V10.913h2.797c.127 0 .254-.13.254-.26l.254-3.247c0-.174-.127-.304-.254-.304h-3.05V4.807c0-.563.423-.996.974-.996h2.16c.17 0 .255-.13.255-.26V.26C10 .13 9.873 0 9.746 0H6.102C3.94 0 2.203 1.776 2.203 3.984v3.162H.254C.127 7.102 0 7.232 0 7.362v3.248c0 .174.127.26.254.26h1.95v.217z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgFacebookIcon;
