import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { PortableText } from 'components';
import { P, H3, Button } from '../../styles/mixins';
import { FadeIn } from 'components';

export default (props) => {
  return (
    <FadeIn>
      <Section $flipped={props.flipped}>
        <Content>
          {props.title && !props.logo ? <H3>{props.title}</H3> : null}
          {props.logo && (
            <GatsbyImage
              objectFit="contain"
              image={props.logo.asset.gatsbyImageData}
              alt={props.title}
            />
          )}

          {props.portableText && <PortableText blocks={props.portableText} />}
          {props.text && <P>{props.text}</P>}
          {props.link && (
            <Link to={props.link}>
              <Button>Learn More</Button>
            </Link>
          )}
        </Content>
        {props.img && <Image image={props.img.asset.gatsbyImageData} alt="Section Image" />}
        {props.image && <Image image={props.image.asset.gatsbyImageData} alt="Section Image" />}
      </Section>
    </FadeIn>
  );
};

const Content = styled.div`
  grid-area: content;
  display: grid;
  img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 2.5rem;
    box-shadow: none;
    margin-bottom: 1.5rem;
  }
  p {
    font-family: 'Poppins', serif;
    font-weight: 300;
    letter-spacing: 1px;
    font-size: 1rem;
    line-height: 1.5em;
    color: var(--muted);
  }
`;

const Image = styled(GatsbyImage)`
  grid-area: image;
  width: 100%;
  height: auto;
  max-height: 350px !important;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15);
`;

const Section = styled(motion.div)`
  width: 100%;
  height: auto;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: 'image' 'content';
  grid-gap: 2rem;
  margin: 2rem auto;
  @media (min-width: 768px) {
    grid-column-gap: 5rem;
    margin: 5rem auto;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: ${({ $flipped }) =>
      $flipped === true ? `'content image'` : `'image content'`};
  }
  @media (min-width: 1200px) {
    margin: 8rem auto;
    margin-top: 4rem;
    max-width: 1200px;
  }
`;
