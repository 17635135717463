import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { H4 } from '../../styles/mixins';
import { wrap } from '@popmotion/popcorn';

const ImagePopup = ({ slide, images }) => {
  const [[page, direction], setPage] = useState([slide, 0]);
  const paginate = (dir) => {
    setPage([page + dir, dir]);
  };
  const currentSlide = wrap(0, images.length, page);

  const variants = {
    enter: (direction) => {
      return {
        x: direction > 0 ? 20 : -20,
        opacity: 0
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1
    },
    exit: (direction) => {
      return {
        zIndex: 0,
        x: direction < 0 ? 20 : -20,
        opacity: 0
      };
    }
  };

  const swipeConfidenceThreshold = 1000;
  const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
  };

  return (
    <AnimatePresence initial={false}>
      <Container>
        <motion.div
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            opacity: { duration: 0.5 }
          }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x);

            if (swipe < -swipeConfidenceThreshold) {
              paginate(1);
            } else if (swipe > swipeConfidenceThreshold) {
              paginate(-1);
            }
          }}>
          <GatsbyImage
            image={images[currentSlide].asset.gatsbyImageData}
            alt={images[currentSlide]?.title}
          />
        </motion.div>
        {images[currentSlide]?.caption && <Caption>{images[currentSlide].caption}</Caption>}
        <Arrows>
          <ArrowLeft
            onClick={() => {
              paginate(-1);
            }}>
            <Arrow />
          </ArrowLeft>
          <ArrowRight
            onClick={() => {
              paginate(+1);
            }}>
            <Arrow />
          </ArrowRight>
        </Arrows>
      </Container>
    </AnimatePresence>
  );
};

const Caption = styled(H4)`
  bottom: -2rem;
  color: var(--main);
  left: 50%;
  margin: 2rem auto;
  max-width: 100%;
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
  width: 500px;
  @media (min-width: 1199px) {
    bottom: 0;
    color: white;
    z-index: 5;
    font-size: 2rem;
    background: rgba(0, 0, 0, 0.25);
    padding: 0.5rem;
  }
`;

const Arrow = styled.div`
  border-bottom: 0.5rem solid white;
  border-left: 0.25rem solid transparent;
  border-right: 0.25rem solid transparent;
  opacity: 1;
  transition: 0.375s;
`;

const ArrowContainer = styled.button`
  background: var(--muted);
  border-radius: 50%;
  cursor: pointer;
  display: grid;
  height: 2.5rem;
  opacity: 0.5;
  outline: none;
  padding-bottom: 0.25rem;
  place-items: center;
  transition: 0.375s;
  width: 2.5rem;

  &:hover {
    opacity: 1;
  }
`;

const ArrowLeft = styled(ArrowContainer)`
  transform: rotate(-90deg);
`;

const ArrowRight = styled(ArrowContainer)`
  transform: rotate(90deg);
  top: 50%;
`;

const Arrows = styled.div`
  align-items: center;
  display: flex;
  height: auto;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  width: calc(100% + 6rem);
  z-index: 5;
  @media (min-width: 768px) {
    visibility: visible;
  }
  @media (min-width: 1200px) {
    visibility: visible;
    width: 100%;
  }
`;

const Container = styled.div`
  align-self: center;
  display: grid;
  height: 80vh;
  max-height: 80vh;
  max-width: calc(100vw - 2rem);

  padding-bottom: ${({ caption }) => (caption ? '4rem' : 0)};
  place-items: center;
  position: relative;
  width: calc(100vw - 2rem);
  @media (min-width: 1199px) {
    max-height: 100vh;
  }
  @media (min-width: 768px) {
    max-width: calc(80vw - 2rem);
    width: calc(80vw - 2rem);
  }

  .gatsby-image-wrapper {
    height: calc(100% - 8rem);
    width: auto;
    > div[aria-hidden='true'] {
      padding-bottom: 0 !important;
      height: 100% !important;
    }
    img {
      pointer-events: none;
    }
  }
  img {
    width: 100%;
    height: 100%;
    max-height: 80vh;
    object-fit: contain !important;
  }
`;

export default ImagePopup;
