exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-appliances-js": () => import("./../../../src/pages/appliances.js" /* webpackChunkName: "component---src-pages-appliances-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-furniture-js": () => import("./../../../src/pages/furniture.js" /* webpackChunkName: "component---src-pages-furniture-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kitchens-classic-js": () => import("./../../../src/pages/kitchens/classic.js" /* webpackChunkName: "component---src-pages-kitchens-classic-js" */),
  "component---src-pages-kitchens-heritage-js": () => import("./../../../src/pages/kitchens/heritage.js" /* webpackChunkName: "component---src-pages-kitchens-heritage-js" */),
  "component---src-pages-kitchens-index-js": () => import("./../../../src/pages/kitchens/index.js" /* webpackChunkName: "component---src-pages-kitchens-index-js" */),
  "component---src-pages-kitchens-modern-js": () => import("./../../../src/pages/kitchens/modern.js" /* webpackChunkName: "component---src-pages-kitchens-modern-js" */),
  "component---src-pages-our-process-js": () => import("./../../../src/pages/our-process.js" /* webpackChunkName: "component---src-pages-our-process-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */)
}

