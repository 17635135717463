import React, { useState, useRef } from 'react';
import ModalContent from '../ModalContent';
import ModalTrigger from '../ModalTrigger';

const Modal = ({
  disableCloseOnClickOutside,
  hideCloseButton,
  modalContent,
  trigger,
  colors
}) => {
  const [isShown, setIsShown] = useState(false);
  const triggerButton = useRef();
  const closeButton = useRef();
  const modal = useRef();


  const showModal = () => {
    setIsShown(true);
    toggleScrollLock();
  };

  const closeModal = () => {
    setIsShown(false);
    document.querySelector('html').classList.remove('scroll-lock');
  };

  const onKeyDown = (event) => {
    if (event.key === 'Escape' && !disableCloseOnClickOutside) {
      closeModal();
    }
  };

  const onClickOutside = (e) => {
    if ((modal && modal.current.contains(e.target)) || disableCloseOnClickOutside) return;
    closeModal();
  };

  const toggleScrollLock = () => {
    document.querySelector('html').classList.toggle('scroll-lock');
  };

  return (
    <>
      <ModalTrigger showModal={showModal} buttonRef={triggerButton} trigger={trigger} />
      {isShown ? (
        <ModalContent
          buttonRef={closeButton}
          closeModal={closeModal}
          content={modalContent}
          hideCloseButton={hideCloseButton}
          modalRef={modal}
          onClickOutside={onClickOutside}
          onKeyDown={onKeyDown}
          colors={colors}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default Modal;