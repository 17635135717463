import React, { useState, useEffect, useRef } from 'react';
import Banner from '../../assets/video/Banner.mp4';
import BannerSmall from '../../assets/video/Banner-small.mp4';

import BannerImage from '../../assets/images/banner.jpg';

import styled from 'styled-components';
import { LogoContrast } from 'assets/svgs';
import { motion } from 'framer-motion';
import { useWindowSize } from 'hooks';

const Header = () => {
  const { windowWidth } = useWindowSize();
  const isMobile = windowWidth < 768;

  const [source, setSource] = useState('');
  const [videoLoaded, setVideoLoaded] = useState(false);

  useEffect(() => {
    isMobile ? setSource(BannerSmall) : setSource(Banner);
  }, []);
  useEffect(() => {
    ref.current.src = source;
    ref.current.load();
  }, [source]);
  const ref = useRef(null);
  return (
    <Container $isMobile={isMobile}>
      <Video
        onCanPlayThrough={() => setVideoLoaded(true)}
        ref={ref}
        autoPlay
        muted
        playsInline
        poster={BannerImage}>
        <source type="video/mp4" />
      </Video>
      {videoLoaded && (
        <Logo
          animate={{ opacity: [0, 1, 1, 0], scale: [1.5, 1, 1, 1] }}
          transition={{ duration: 5 }}>
          <LogoContrast />
        </Logo>
      )}
    </Container>
  );
};

const Video = styled.video`
  width: 100%;
  height: 100%;
  background: black;
  position: absolute;
  z-index: 0;
  left: 0;
  bottom: 0;
`;

const Logo = styled(motion.div)`
  width: clamp(20rem, 80vw, 40rem);
  height: auto;
  position: relative;
`;

const Container = styled.div`
  background: black;
  aspect-ratio: 16/9;
  position: relative;
  display: grid;
  place-items: center;
  place-content: center;
  margin-top: ${({ $isMobile }) => ($isMobile ? '5rem' : 0)};
`;

export default Header;
