import React from 'react';
import { motion } from 'framer-motion';
import { GatsbyImage } from "gatsby-plugin-image";
import styled from 'styled-components';
import { Parallax, Background } from 'react-parallax';
import { FadeIn } from 'components';

export default (props) => {
  return (
    <FadeIn>
      <Container style={{ backgroundColor: props.backgroundColor }}>
        <Parallax strength={300}>
          <Background className="custom-bg">
            <GatsbyImage image={props.image} alt="Parallax Image" />
          </Background>
        </Parallax>
        <Content>{props.children}</Content>
      </Container>
    </FadeIn>
  );
};

const Content = styled.div`
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-width: 1440px;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Container = styled(motion.div)`
  width: 100%;
  height: 100%;
  padding: 0;
  overflow: hidden;
  height: 500px;
  background: black;
  position: relative;
  .react-parallax {
    height: 700px;
    .react-parallax-background-children {
      width: 100%;
    }
  }
  .custom-bg {
    width: 100%;
    height: 700px;
  }
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    opacity: 50%;
    transition: 0.5s;
  }

  @media (min-width: 1150px) {
    padding: 0;
  }
`;
