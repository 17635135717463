import React from 'react';
import styled from 'styled-components';
import { FadeIn } from 'components';

export default ({ children }) => {
  return (
    <FadeIn>
      <Container>
        <Content>{children}</Content>
      </Container>
    </FadeIn>
  );
};

const Content = styled.div`
  width: 100%;
  height: auto;
  max-width: 800px;
  position: relative;
  padding: 1rem;
  @media (min-width: 768px) and (max-width: 1199px) {
    max-width: calc(100% - 20rem);
    margin: 0 auto;
  }
`;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
