import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FacebookIcon, TwitterIcon, LinkedinIcon, InstaIcon } from 'assets/svgs';
import { motion } from 'framer-motion';
import { Container, FadeIn } from 'components';
import { useWindowSize } from 'hooks';
import { H1 } from '../../styles/mixins';

export default ({ banner }) => {
  const { windowHeight, windowWidth } = useWindowSize();

  return (
    <Container>
      <FadeIn>
        <Carousel windowHeight={windowHeight}>
          <Est>Est. 2005</Est>
          <Slide>
            {banner.img && (
              <Banner image={banner.img.childImageSharp.gatsbyImageData} alt={banner.caption} />
            )}
            {banner.sanityImage && (
              <Banner image={banner.sanityImage.asset.gatsbyImageData} alt={banner.caption} />
            )}
          </Slide>
          {windowWidth >= 768 && (
            <Socials>
              <Social href="https://business.facebook.com/colinmaherdesign/" target="blank">
                <FacebookIcon />
              </Social>
              <Social href="https://twitter.com/" target="blank">
                <TwitterIcon />
              </Social>
              <Social href="https://www.linkedin.com/company/18601203/" target="blank">
                <LinkedinIcon />
              </Social>
              <Social href="https://www.instagram.com/colinmaherdesign/" target="blank">
                <InstaIcon />
              </Social>
            </Socials>
          )}
        </Carousel>
      </FadeIn>
      {banner.caption ? <Caption>{banner.caption}</Caption> : null}
    </Container>
  );
};

const Est = styled.span`
  position: absolute;
  font-size: 1rem;
  color: var(--muted);
  font-family: 'Poppins', serif;
  font-weight: 400;
  font-style: normal;
  top: 1rem;
  left: -4.5rem;
  transform: translateY(50%) rotate(-90deg);
  letter-spacing: 1pt;
  @media (min-width: 768px) {
    left: -4rem;
  }
`;

const Social = styled.a`
  svg {
    height: 1rem;
    width: 1rem;
    transition: 0.5s;

    path {
      transition: 0.5s;
      fill: var(--muted);
    }
  }
  &:hover {
    svg {
      transform: scale(1.1);
      path {
        fill: var(--main);
      }
    }
  }
`;

const Socials = styled.div`
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
  width: 12.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (min-width: 768px) {
    right: -8rem;
  }
`;

const Caption = styled(H1)`
  text-align: center;
  color: var(--main);
`;

const Slide = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: relative;
`;

const Banner = styled(GatsbyImage)`
  width: 100%;
  height: 50vh;
  z-index: 1;
  @media (min-width: 768px) and (max-width: 1199px) and (orientation: portrait) {
    height: 25vh;
  }
  @media (min-width: 1199px) {
    height: calc(100vh - 13rem);
  }
`;

const Carousel = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
  height: auto;
  margin-bottom: 5rem;
  @media (min-width: 768px) {
    max-width: 100%;
    margin-top: 12rem;
  }
  @media (min-width: 1200px) {
    width: 100%;
    margin-top: 10rem;
  }
`;
