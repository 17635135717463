import React from 'react';
import styled from 'styled-components';


export default ({ children, bg }) => {
  return (
    <Container bg={bg}>
      <Content>{children}</Content>
    </Container>
  );
};

const Content = styled.div`
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  @media (min-width: 768px) {
    max-width: calc(100% - 8rem);
  }
`;

const Container = styled.div`
  background: ${({ bg }) => (bg ? `${bg}` : 'none')};
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
