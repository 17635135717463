import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { motion, AnimatePresence } from 'framer-motion';
import { wrap } from '@popmotion/popcorn';
import { PortableText } from 'components';
import { useWindowSize } from 'hooks';
import { P } from '../../../styles/mixins';

const TestimonialsCarousel = () => {
  const data = useStaticQuery(
    graphql`
      query TestimonialsQuery {
        testimonials: allSanityTestimonial {
          edges {
            node {
              clientName
              _rawBody
            }
          }
        }
      }
    `
  );

  const { edges: testimonials } = data.testimonials;

  const { windowHeight, windowWidth } = useWindowSize();
  const swipeConfidenceThreshold = 10000;
  const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
  };
  const [[page, direction], setPage] = useState([0, 0]);
  const paginate = (dir) => {
    setPage([page + dir, dir]);
  };

  useEffect(() => {
    const timer = setTimeout(() => paginate(1), 10000);
    return () => clearTimeout(timer);
  }, [page]);

  const index = wrap(0, testimonials.length, page);

  const variants = {
    enter: (direction) => {
      return {
        x: direction > 0 ? 1000 : -1000,
        opacity: 0
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1
    },
    exit: (direction) => {
      return {
        zIndex: 0,
        x: direction < 0 ? 1000 : -1000,
        opacity: 0
      };
    }
  };

  return (
    <AnimatePresence initial={false} custom={direction}>
      <Carousel windowHeight={windowHeight}>
        <Slide
          key={page}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: 'spring', stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 }
          }}
          drag="x"
          dragDirectionLock
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x);

            if (swipe < -swipeConfidenceThreshold) {
              paginate(1);
            } else if (swipe > swipeConfidenceThreshold) {
              paginate(-1);
            }
          }}>
          <Quote>
            <PortableText blocks={testimonials[index].node._rawBody} />
          </Quote>
          <Author>~ {testimonials[index].node.clientName} ~</Author>
        </Slide>
      </Carousel>
    </AnimatePresence>
  );
};

const Quote = styled.div`
  color: var(--muted);
  text-align: center;
  margin-bottom: 1.5em;
  p {
    &:before {
      content: '"';
    }
    &:after {
      content: '"';
    }
  }
`;

const Author = styled(P)`
  font-family: 'Poppins';
  font-size: 1.25rem;
  color: var(--main);
  text-align: center;
`;

const Slide = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: relative;
  cursor: grab;
`;
const Carousel = styled.div`
  width: auto;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  height: auto;
  margin-bottom: 6rem;
`;

export default TestimonialsCarousel;
