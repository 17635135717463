import React from 'react';
import { Link } from 'gatsby';
import { LogoContrast, FacebookIcon, TwitterIcon, LinkedinIcon, InstaIcon } from 'assets/svgs';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { useWindowSize } from 'hooks';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

const Navbar = ({ navOpen, toggleNav, location }) => {
  const { windowWidth, windowHeight } = useWindowSize();

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1, delay: location.pathname === '/' && windowWidth >= 768 ? 5 : 0 }}>
      <NavBackground />
      <NavContainer navOpen={navOpen}>
        <LogoButton aria-label="Home" to="/#home" stripHash>
          <LogoContrast />
        </LogoButton>
        {windowWidth < 768 && (
          <>
            <MenuToggle navOpen={navOpen} onClick={toggleNav}>
              <span />
              <span />
            </MenuToggle>
            <AnimatePresence>
              {navOpen && (
                <Links
                  windowHeight={windowHeight}
                  initial={{ x: -windowWidth }}
                  animate={{ x: 0, opacity: 1 }}
                  exit={{ x: -windowWidth }}
                  transition={{ duration: 0.5 }}>
                  <FirstLinkSegment onClick={toggleNav}>
                    <Link to="/">Home</Link>
                    <Link to="/kitchens">Kitchens</Link>
                    <Link to="/furniture">Furniture</Link>
                  </FirstLinkSegment>
                  <SecondLinkSegment onClick={toggleNav}>
                    <Link to="/appliances">Appliances</Link>
                    <Link to="/our-process">Our Process</Link>
                    <Link to="/contact">Contact</Link>
                  </SecondLinkSegment>
                  {windowWidth < 768 && (
                    <Socials>
                      <Social
                        aria-label="Facebook"
                        href="https://business.facebook.com/colinmaherdesign/"
                        target="blank">
                        <FacebookIcon />
                      </Social>
                      <Social aria-label="Twitter" href="https://twitter.com/" target="blank">
                        <TwitterIcon />
                      </Social>
                      <Social
                        aria-label="Linked In"
                        href="https://www.linkedin.com/company/18601203/"
                        target="blank">
                        <LinkedinIcon />
                      </Social>
                      <Social
                        aria-label="Instagram"
                        href="https://www.instagram.com/colinmaherdesign/"
                        target="blank">
                        <InstaIcon />
                      </Social>
                    </Socials>
                  )}
                </Links>
              )}
            </AnimatePresence>
          </>
        )}
        {windowWidth >= 768 && (
          <>
            <FirstLinkSegment onClick={toggleNav}>
              <Link to="/">Home</Link>
              <Link to="/kitchens">Kitchens</Link>
              <Link to="/furniture">Furniture</Link>
            </FirstLinkSegment>
            <SecondLinkSegment onClick={toggleNav}>
              <Link to="/appliances">Appliances</Link>
              <Link to="/our-process">Our Process</Link>
              <Link to="/contact">Contact</Link>
            </SecondLinkSegment>
          </>
        )}
      </NavContainer>
    </Container>
  );
};

const Social = styled.a`
  svg {
    height: 1.5rem;
    width: 1.5rem;
    path {
      fill: white;
    }
  }
`;

const Socials = styled.div`
  position: absolute;
  bottom: 6.25rem;
  left: 50%;
  transform: translateX(-50%);
  width: 12.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MenuToggle = styled.div`
  grid-area: toggle;
  position: relative;
  width: 5rem;
  height: 5rem;
  span {
    width: 2rem;
    height: 1px;
    display: block;
    background: white;
    transition: 0.5s;
    &:first-child {
      position: absolute;
      top: ${({ navOpen }) => (navOpen ? '50%' : '2.125rem')};
      left: 50%;
      transform: ${({ navOpen }) =>
        navOpen ? 'translate(-50%, -50%) rotate(45deg)' : 'translate(-50%, -50%)'};
    }
    &:last-child {
      position: absolute;
      top: ${({ navOpen }) => (navOpen ? '50%' : '2.875rem')};
      left: 50%;
      transform: ${({ navOpen }) =>
        navOpen ? 'translate(-50%, -50%) rotate(-45deg)' : 'translate(-50%, -50%)'};
    }
  }
`;

const LogoButton = styled(AnchorLink)`
  grid-area: logo;
  width: 15rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 1.25rem;
  svg {
    height: 3rem;
    width: 15rem;
    transition: 0.375s;
  }
  @media (min-width: 768px) {
    margin-left: 0;
    align-items: center;
    justify-content: center;
    justify-self: center;
  }
`;

const LinkSegment = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0rem;
  a {
    align-items: center;
    color: #ededed;
    display: flex;
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
    font-weight: 400;
    height: 4rem;
    justify-content: flex-start;
    letter-spacing: 1pt;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
    position: relative;
    text-transform: uppercase;
    transition: 0.5s;
    white-space: nowrap;
    width: 100%;
    &:hover {
      color: white;
      background: rgba(0, 0, 0, 0.05);
      border-bottom: 2px solid white;
    }
  }
  @media (min-width: 768px) {
    background: transparent;
    flex-direction: row;
    padding: 0;
    a {
      font-size: 0.825rem;
      width: auto;
      align-items: center;
      height: 2rem;
      justify-content: center;
      padding: 0 1rem;
      transition: 0.5s;
      &:after {
        content: '';
        display: block;
        opacity: 0;
        position: absolute;
        bottom: -0.5rem;
        transform: translateX(-50%);
        left: 50%;
        width: 0.25rem;
        height: 0.25rem;
        border-radius: 50%;
        border: 1px solid rgba(255, 255, 255, 0.75);
        transition: 0.5s;
      }
      &:hover {
        background: rgba(255, 255, 255, 0.05);
        background: none;
        border-bottom: none;
        &:after {
          opacity: 1;
        }
      }
    }
  }
`;

const FirstLinkSegment = styled(LinkSegment)`
  grid-area: firstLinkSegment;
  @media (min-width: 768px) {
    justify-content: flex-end;
  }
  @media (min-width: 1200px) {
    justify-content: space-between;
  }
`;

const SecondLinkSegment = styled(LinkSegment)`
  grid-area: secondLinkSegment;
  @media (min-width: 768px) {
    justify-content: flex-start;
  }
  @media (min-width: 1200px) {
    justify-content: space-between;
  }
`;

const Links = styled(motion.div)`
  grid-area: links;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: ${({ windowHeight }) => `${windowHeight}px`};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: #252525;
  padding: 1rem;
`;

const NavBackground = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  height: 5rem;
  width: 100%;
  transition: 0.375s;
  background: #252525;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15);
  @media (min-width: 768px) {
    height: 8rem;
    box-shadow: none;
    background: rgba(25, 25, 25, 0.9);
  }
  @media (min-width: 1200px) {
    height: 6rem;
  }
`;

const NavContainer = styled.nav`
  position: relative;
  width: 100%;
  height: ${({ navOpen }) => (navOpen ? '100vh' : 'auto')};
  display: grid;
  grid-template-columns: 1fr 5rem;
  grid-template-rows: 5rem 1fr;
  grid-gap: 0;
  grid-template-areas: 'logo toggle' 'links links';
  transition: 0.375s;
  align-items: flex-start;

  @media (min-width: 768px) {
    height: 6rem;
    padding: 1rem;
    max-width: 720px;
    row-gap: 0.75rem;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'logo logo' 'firstLinkSegment secondLinkSegment';
  }
  @media (min-width: 1200px) {
    height: 6rem;
    align-items: center;
    max-width: 1200px;
    grid-template-columns: 1fr 24rem 1fr;
    grid-template-areas: 'firstLinkSegment logo secondLinkSegment';
  }
`;

const Container = styled(motion.div)`
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default Navbar;
