import React from "react";
import { motion } from "framer-motion";
import { useIntersection } from 'hooks';


export default ({ children }) => {
  const { observerEntry, elRef } = useIntersection({ threshold: 0 });

  return (
    <motion.div
      ref={elRef}
      initial={{opacity:0}}
      animate={observerEntry.isIntersecting ? {opacity:1} : {opacity:0}}
      transition={{ duration: 1 }}
    >
      {children}
    </motion.div>
  );
}