import * as React from 'react';

function SvgTwitterIcon(props) {
  return (
    <svg viewBox="0 0 20 16" fill="none" {...props}>
      <path
        d="M19.944 1.933c-.648.276-1.337.433-2.025.55.323-.039.81-.628 1.012-.864.284-.354.526-.747.688-1.18 0-.038.041-.078 0-.117h-.121c-.77.393-1.539.668-2.39.904-.08 0-.121 0-.162-.04-.08-.078-.121-.157-.202-.196a5.761 5.761 0 00-1.175-.707c-.567-.197-1.215-.315-1.822-.275a4.22 4.22 0 00-1.701.471c-.527.275-1.013.63-1.377 1.1a3.672 3.672 0 00-.81 1.573c-.122.55-.122 1.1-.04 1.69 0 .079 0 .118-.082.079-3.28-.472-5.994-1.612-8.222-4.088-.08-.118-.162-.118-.243 0-.972 1.415-.486 3.695.73 4.795.161.157.323.315.526.432-.081 0-.891-.078-1.58-.432-.08-.079-.162-.04-.162.079 0 .157 0 .275.04.471C1.03 7.593 2.043 8.93 3.42 9.441c.162.078.364.118.526.157-.324.078-.648.118-1.58.04-.12-.04-.161.038-.12.156.688 1.808 2.186 2.359 3.28 2.673.162.04.284.04.446.079l-.041.039c-.365.55-1.66.943-2.228 1.14-1.093.354-2.268.55-3.402.432-.202-.04-.202-.04-.283 0-.04.04 0 .079.04.118.243.157.486.275.689.393.729.354 1.498.668 2.268.865 4.09 1.1 8.708.275 11.745-2.673C17.19 10.541 18 7.318 18 4.135c0-.118.163-.197.244-.276a8.598 8.598 0 001.579-1.611c.122-.118.081-.275.081-.315.122-.078.122-.039.04 0z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.618 15.965c0-.04.365 0 0 0 0-.04.04 0 0 0z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgTwitterIcon;
