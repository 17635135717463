import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { FadeIn, Container } from 'components';
import { H3 } from '../../styles/mixins';

const KitchenStyles = () => {
  const data = useStaticQuery(graphql`
    query {
      categories: allSanityCategory {
        edges {
          node {
            title
            slug {
              current
            }
            description
            categoryImage {
              asset {
                gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  `);
  const { edges: categories } = data.categories;

  return (
    <Container>
      <FadeIn>
        <Grid>
          {categories.map(({ node }, i) => (
            <GridItem to={`/kitchens/${node.slug.current}`} key={i}>
              <Content>
                <GatsbyImage image={node.categoryImage.asset.gatsbyImageData} alt={node.title} />
              </Content>
              <Title>
                <H3>{node.title} Kitchens</H3>
              </Title>
            </GridItem>
          ))}
        </Grid>
      </FadeIn>
    </Container>
  );
};

const Title = styled.div`
  width: 100%;
  height: auto;
  padding-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  position: relative;
  width: 100%;
`;
const GridItem = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  transition: 0.5s;
  opacity: 1;

  @media only screen and (min-width: 1199px) {
    opacity: 0.75;
    &:hover {
      opacity: 1;
    }
  }
  &:last-child {
    @media only screen and (min-width: 768px) and (max-width: 1199px) {
      margin-right: -100%;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  padding: 1rem;

  @media (min-width: 768px) {
    padding: 0;

    margin: 1rem auto 3rem auto;
    max-width: 720px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: ${({ flipped }) =>
      flipped === true ? `'content image'` : `'image content'`};
  }
  @media (min-width: 1200px) {
    max-width: 1200px;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export default KitchenStyles;
