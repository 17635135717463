import styled from 'styled-components';

export const Button = styled.button`
  align-items: center;
  background: rgba(255, 255, 255, 0.25);
  border-radius: 0.25rem;
  border: 1px solid;
  color: ${({ contrast }) => (contrast ? 'white' : '#252525')};
  cursor: pointer;
  display: flex;
  font-family: 'Poppins';
  font-size: 0.75rem;
  height: 3rem;
  justify-content: center;
  letter-spacing: 3pt;
  line-height: 1.5em;
  margin: 2rem 0;
  outline: none;
  padding: 0.5rem 1.5rem;
  text-transform: uppercase;
  transition: 0.5s;
  width: auto;

  &:hover {
    border: 1px solid;

    background: ${({ contrast }) => (contrast ? 'white' : '#252525')};
    color: ${({ contrast }) => (contrast ? '#252525' : 'white')};
  }
`;

export const P = styled.p`
  color: var(--muted);
  font-family: 'Poppins', serif;
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 1pt;
  line-height: 1.5em;
  margin-top: 0;
  strong {
    font-weight: 600;
    color: var(--main);
  }
`;

export const H1 = styled.h1`
  color: var(--main);
  font-family: 'Poppins', serif;
  font-size: 2rem;
  font-weight: 300;
  letter-spacing: 1pt;
  line-height: 1.25em;
  margin-bottom: 1em;
  text-align: left;
  @media (min-width: 768px) {
    font-size: 2.5rem;
  }
`;

export const H2 = styled.h2`
  color: var(--main);
  font-family: 'Poppins', serif;
  font-size: 2rem;
  font-weight: 300;
  letter-spacing: 1pt;
  line-height: 1.25em;
  margin-bottom: 1em;
  text-align: left;
  @media (min-width: 768px) {
    font-size: 2.5rem;
  }
`;

export const H3 = styled.h3`
  color: var(--main);
  font-family: 'Poppins', serif;
  font-size: 1.25rem;
  font-weight: 300;
  letter-spacing: 1pt;
  line-height: 1.25em;
  margin-bottom: 1em;
  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const H4 = styled.h4`
  color: var(--main);
  font-family: 'Poppins', serif;
  font-size: 1.25rem;
  font-weight: 300;
  letter-spacing: 1pt;
  line-height: 1.25em;
  margin-bottom: 1em;
  text-transform: uppercase;
`;

export const H5 = styled.h5`
  color: var(--main);
  font-family: 'Poppins', serif;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 1pt;
  line-height: 1.25em;
  margin-bottom: 1em;
  text-transform: uppercase;
`;

export const UL = styled.ul`
  color: var(--main);
  margin-left: 2rem;
  padding: 0;
  li {
    color: var(--muted);
    font-size: 0.875rem;
    letter-spacing: 1pt;
    line-height: 1.5em;
    margin-bottom: 0.5em;
  }
`;

export const A = styled.a`
  color: dodgerblue;
  &:visited {
    color: gray;
  }
`;
