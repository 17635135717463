import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const ModalContent = (props) => {
  const { colors } = props;
  return ReactDOM.createPortal(
    <ModalCover
      tag="aside"
      role="dialog"
      tabIndex="-1"
      aria-modal="true"
      className="modal-cover"
      onKeyDown={props.onKeyDown}>
      <ModalArea
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        onClick={props.onClickOutside}>
        <ModalClose
          ref={props.buttonRef}
          aria-label="Close Modal"
          aria-labelledby="close-modal"
          onClick={props.closeModal}
          colors={colors}>
          <HideVisual id="close-modal">Close</HideVisual>
          <ModalCloseIcon colors={colors} viewBox="0 0 40 40">
            <path d="M 10,10 L 30,30 M 30,10 L 10,30" />
          </ModalCloseIcon>
        </ModalClose>
        <ModalBody ref={props.modalRef} onClick={(e) => e.stopPropagation()}>
          {props.content}
        </ModalBody>
      </ModalArea>
    </ModalCover>,
    document.body
  );
};

const ModalCover = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  transform: translateZ(0);
  background-color: rgba(255, 255, 255, 0.75);
`;

const ModalArea = styled(motion.aside)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  padding: 20px;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
  @media (min-width: 768px) {
    top: 10px;
    left: 10px;
    width: calc(100% - 20px);
    height: calc(100vh - 20px);
  }
`;

const ModalClose = styled.button`
  position: fixed;
  top: 25px;
  right: 25px;
  padding: 0.5em;
  line-height: 1;
  background: #555;
  border: 0;
  box-shadow: 0;
  z-index: 20;
  cursor: pointer;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  svg {
    path {
      stroke: white;
      fill: white;
    }
  }

  @media (min-width: 500px) {
    right: 35px;
    top: 35px;
  }
`;

const ModalCloseIcon = styled.svg`
  width: 30px;
  height: 30px;
  stroke-linecap: round;
  stroke-width: 3;
`;

const ModalBody = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  padding: 6rem 1rem;
  background: white;
  form {
    margin-bottom: 20px;
  }
  @media (min-width: 1150px) {
    padding: 1rem;
  }
`;
const HideVisual = styled.span`
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
`;

export default ModalContent;
