import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { P, H4, Button } from '../../styles/mixins';
import { FadeIn } from 'components';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

const Tiles = () => {
  const data = useStaticQuery(graphql`
    {
      tile1: file(relativePath: { eq: "assets/images/Tile__1.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED)
        }
      }
      tile2: file(relativePath: { eq: "assets/images/Tile__2.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED)
        }
      }
    }
  `);

  return (
    <FadeIn>
      <TilesContainer>
        <Tile>
          <GatsbyImage image={data.tile1.childImageSharp.gatsbyImageData} alt="Our Process" />
          <Content>
            <H4>Our Design Process</H4>
            <P>
              Learn more about how we design our kitchens from start to finish. Let us walk you
              through our design process and how we build bespoke kitchens that inspire.
            </P>
            <Link to="/our-process">
              <Button contrast>Design Proceess</Button>
            </Link>
          </Content>
        </Tile>
        <Tile>
          <GatsbyImage image={data.tile2.childImageSharp.gatsbyImageData} alt="Contact Us" />
          <Content>
            <H4>Book A Visit To Our Studio</H4>
            <P>
              Book a visit to our design studio. We are currently open by appointment only so your
              visit must be booked in advance.
            </P>
            <AnchorLink to="/contact#get-in-touch" stripHash>
              <Button contrast>Contact Us</Button>
            </AnchorLink>
          </Content>
        </Tile>
      </TilesContainer>
    </FadeIn>
  );
};

const Content = styled.div`
  align-items: center;
  aspect-ratio: 1/1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 50%;
  overflow: hidden;
  padding: 1rem;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 1;
  h4 {
    color: white;
    text-align: center;
    font-size: 1.25rem;
  }
  p {
    color: white;
    text-align: center;
    font-size: 0.875rem;
  }
  button {
    margin: 0.5rem auto;
  }

  @media (min-width: 1200px) {
    padding: 2rem;
  }
`;

const Tile = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  height: 0px;
  overflow: hidden;
  img {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const TilesContainer = styled.div`
  margin: 3rem auto;
  width: 1000px;
  max-width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  padding: 1rem;
  @media (min-width: 768px) {
    padding: 0;
    grid-template-columns: 1fr 1fr;
    margin: 5rem auto;
  }
`;

export default Tiles;
