import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { PortableText, ImagePopup, Modal } from 'components';
import { wrap } from '@popmotion/popcorn';
import { Zoom } from 'assets/svgs';
import { H2, H4 } from '../../styles/mixins';

const ProjectPopup = ({ node }) => {
  const [[page, direction], setPage] = useState([0, 0]);
  const paginate = (dir) => {
    setPage([page + dir, dir]);
  };

  const firstSlide = wrap(0, node.projectImages.length, page);
  const secondSlide = wrap(0, node.projectImages.length, page + 1);
  const thirdSlide = wrap(0, node.projectImages.length, page + 2);
  const fourthSlide = wrap(0, node.projectImages.length, page + 3);

  return (
    <Container>
      <motion.div
        key={page}
        initial={{ opacity: 0.5 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 1, y: 20 }}
        transition={{ duration: 0.5 }}>
        <Modal
          trigger={
            <AnimatePresence initial={false}>
              <ImgContainer>
                <GatsbyImage
                  image={node.projectImages[firstSlide].asset.gatsbyImageData}
                  alt={node.title}
                />
                <Zoom />
              </ImgContainer>
            </AnimatePresence>
          }
          modalContent={<ImagePopup slide={firstSlide} images={node.projectImages} />}
        />
        <GalleryThumbnails>
          <ArrowLeft
            onClick={() => {
              paginate(-1);
            }}
          />
          <GalleryButton
            onClick={() => {
              paginate(1);
            }}>
            <GatsbyImage
              image={node.projectImages[secondSlide].asset.gatsbyImageData}
              alt={node.projectImages[secondSlide].caption}
            />
          </GalleryButton>
          <GalleryButton
            onClick={() => {
              paginate(2);
            }}>
            <GatsbyImage
              image={node.projectImages[thirdSlide].asset.gatsbyImageData}
              alt={node.projectImages[thirdSlide].caption}
            />
          </GalleryButton>
          <GalleryButton
            onClick={() => {
              paginate(3);
            }}>
            <GatsbyImage
              image={node.projectImages[fourthSlide].asset.gatsbyImageData}
              alt={node.projectImages[fourthSlide].caption}
            />
          </GalleryButton>
          <ArrowRight
            onClick={() => {
              paginate(1);
            }}
          />
        </GalleryThumbnails>
      </motion.div>

      <div>
        <H2>{node.title}</H2>
        <H4>Project Description:</H4>
        <PortableText blocks={node._rawBody} />
      </div>
    </Container>
  );
};

const Arrow = styled.button`
  background: none;
  border-bottom: 1rem solid var(--muted);
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  cursor: pointer;
  height: 1rem;
  opacity: 0.5;
  outline: none;
  position: absolute;
  transition: 0.375s;
  width: 0;
  width: 1rem;
  z-index: 1;
  &:hover {
    border-bottom: 1rem solid var(--muted);
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    opacity: 1;
  }
`;

const ArrowLeft = styled(Arrow)`
  transform: translate(-50%, -50%) rotate(-90deg);
  left: 0rem;
  top: 50%;
  @media (min-width: 768px) {
    left: -1.5rem;
  }
`;

const ArrowRight = styled(Arrow)`
  transform: translate(-50%, -50%) rotate(90deg);
  right: -1rem;
  top: 50%;
  @media (min-width: 768px) {
    right: -2.5rem;
  }
`;

const ImgContainer = styled.div`
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 75%;
  overflow: hidden;
  border-radius: 0.25rem;
  .gatsby-image-wrapper {
    width: 100%;
    height: 0;
    padding-bottom: 75%;
    img {
      pointer-events: none;
    }
  }
  svg {
    border-top-left-radius: 0.25rem;
    background: rgba(0, 0, 0, 0.5);
    padding: 0.625rem;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 2rem;
    height: 2rem;
    z-index: 2;
    path {
      fill: white;
    }
  }
`;

const GalleryButton = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 75%;
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 0.25rem;
  overflow: hidden;
  opacity: 0.75;
  transition: 0.5s;
  position: relative;
  .gatsby-image-wrapper {
    width: 100%;
    height: 0;
    padding-bottom: 75%;
  }
  img {
    object-fit: cover !important;
    pointer-events: none;
  }
  &:hover {
    transform: translateY(-0.25rem);
    opacity: 1;
    box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.15);
  }
`;

const GalleryThumbnails = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr;
  height: auto;
  padding: 1.5rem;
  position: relative;
  width: 100%;

  @media (min-width: 768px) {
    padding: 1.5rem 0;
    gap: 1.5rem;
  }
`;

const Container = styled.div`
  height: 100%;
  width: 1200px;
  max-width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 4rem;

  p {
    font-family: 'Poppins', serif;
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.5em;
    color: var(--main);
  }
  @media (min-width: 768px) {
    padding: 3rem 2rem;
  }
  @media (min-width: 1200px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 6rem;
  }
`;

export default ProjectPopup;
