import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';
import { LogoContrast, FacebookIcon, TwitterIcon, LinkedinIcon, InstaIcon } from 'assets/svgs';
import { P } from '../../styles/mixins';

export default () => {
  return (
    <Container>
      <Footer>
        <LogoButton aria-label="Our Logo">
          <LogoContrast />
        </LogoButton>
        <Socials>
          <Social
            aria-label="Facebook"
            href="https://business.facebook.com/colinmaherdesign/"
            target="blank">
            <FacebookIcon />
          </Social>
          <Social aria-label="Twitter" href="https://twitter.com/" target="blank">
            <TwitterIcon />
          </Social>
          <Social
            aria-label="Linked In"
            href="https://www.linkedin.com/company/18601203/"
            target="blank">
            <LinkedinIcon />
          </Social>
          <Social
            aria-label="Instagram"
            href="https://www.instagram.com/colinmaherdesign/"
            target="blank">
            <InstaIcon />
          </Social>
        </Socials>
        <P style={{ textAlign: 'center' }}>
          Unit 9b, Finglas Business Centre, Jamestown Rd, Finglas, Dublin 11
        </P>
        <P>
          <strong>01-485-1176</strong>
        </P>
        <Privacy to="/privacy">Our Privacy Policy</Privacy>
      </Footer>
    </Container>
  );
};

const Privacy = styled(Link)`
  font-size: 0.875rem;
  color: #c4c4c4;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  &:visited {
    color: #a4a4a4;
  }
  &:hover {
    color: white;
  }
`;

const Social = styled.a`
  svg {
    height: 1rem;
    width: 1rem;
    path {
      fill: white;
    }
  }
  &:hover {
    svg {
      transition: 0.5s;
      transform: scale(1.1);
      path {
        transition: 0.5s;
        fill: white;
      }
    }
  }
`;

const Socials = styled.div`
  width: 10rem;
  height: 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const LogoButton = styled.button`
  outline: none;
  border: none;
  height: 2.5rem;
  width: auto;
  margin-bottom: 1.5rem;
  background: none;
  svg {
    height: 2.5rem;
    width: auto;
  }
`;

const Footer = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem 1rem;
  color: white;
  ${P} {
    font-family: 'Poppins';
    color: white;
    font-size: 0.875rem;
    strong {
      color: white;
      font-family: 'Poppins';
    }
  }
`;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #252525;
  margin-top: 5rem;
`;
