import React from 'react';
import { useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FadeIn, Container, Modal, ImagePopup } from 'components';

const FurnitureGallery = () => {
  const data = useStaticQuery(graphql`
    query FurnitureQuery {
      galleries: allSanityFurnitureGallery {
        edges {
          node {
            title
            furnitureImages {
              asset {
                gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
              }
              caption
            }
          }
        }
      }
    }
  `);

  const { edges: galleries } = data.galleries;

  return (
    <FadeIn>
      <Container>
        {galleries.map(({ node }, i) => {
          const images = node.furnitureImages;

          return (
            <GalleryContainer key={i}>
              {images.map((image, i) => (
                <Modal
                  key={i}
                  trigger={<StyledImg image={image.asset.gatsbyImageData} alt={node.title} />}
                  modalContent={<ImagePopup slide={i} images={images} />}
                />
              ))}
            </GalleryContainer>
          );
        })}
      </Container>
    </FadeIn>
  );
};

const StyledImg = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  opacity: 0.75;
  transition: 0.375s;
  aspect-ratio: 1/1;
  &:hover {
    opacity: 1;
  }
  img {
    width: 100%;
    height: 100%;
  }
`;

const GalleryContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 2rem 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.5rem;
  padding: 1rem;
  @media (min-width: 1150px) {
    grid-template-columns: repeat(4, 1fr);
    margin: 0 auto;
    max-width: 1200px;
    padding: 5rem;
    padding-top: 0;
  }
`;

export default FurnitureGallery;
