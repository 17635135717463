import React, { useState, useEffect } from 'react';
import { Navbar, Footer, IENotSupportedSplash, CookieBanner } from 'components';
import { isIE } from 'react-device-detect';
import '../../assets/fonts/font.css';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`

  /* Root Variables */
  :root {
    --main: #242424;
    --light: #ededed;
    --muted: #737373;
  }

  /* Default Overrides */
  * {
      margin:0;
      padding:0;
      box-sizing:border-box;
      font-family: 'Poppins', sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-display:swap;
  }
  html{
    font-size: 16px;
    overflow-x:hidden;
  }
  body{
    background: white;
    overflow-x:hidden;
  }

  h1,h2,h3,h4,h5,h6{
    margin-block-start:0;
    margin-block-end:0;
    margin-bottom:1em;
  }
  p{
    margin-block-start:0;
    margin-block-end:0;
    margin-bottom:1em;
  }

  button{
    border:none;
    outline:none;
    :focus, :hover{
        outline:none;
        border:none;
    }
  }
  a{
    text-decoration:none;
  }

  /* Scroll Lock */
  .scroll-lock {
      overflow: hidden;
  }
`;

const Layout = ({ children, location }) => {
  const [navOpen, setNavOpen] = useState(false);
  const toggleNav = () => {
    setNavOpen(!navOpen);
  };

  return !isIE ? (
    <>
      <GlobalStyles />
      <Navbar navOpen={navOpen} toggleNav={toggleNav} location={location} />
      {children}
      <Footer />
    </>
  ) : (
    <IENotSupportedSplash />
  );
};

export default Layout;
